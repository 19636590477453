import axios from 'axios';

import { 
  getCpapApiEndpoint, 
  setAxiosDefaultHeaders 
} from './apiBaseUtilities';
import { getFilteredAssesments } from './apiGetUtilities';

import { deleteCloudVideo } from './utilities';
import { FirebaseUserProps } from './entityUtilities';
import { getSubjectDetails } from './apiGetUtilities';
import { AssessmentSchemaProps } from './mongoSchemas';
const cpapApiEndpoint = getCpapApiEndpoint();



export async function deleteUserAccount(firebaseUser: any){
  
    console.log('Deleting user account');
    firebaseUser?.firebaseUserObject.delete().then ( ()=>{
      console.log('User deleted');
    }).catch((err:any)=>{
    console.log('Error at API:', err);
    });
  
  }

  export async function deleteAssessment(firebaseUser:FirebaseUserProps, assessmentId:string){
    if(!cpapApiEndpoint){
      return false;
    }
  
    const url = `${cpapApiEndpoint}assessments/${assessmentId}`;
    try{
      const {uid, bearerToken } = firebaseUser;
      setAxiosDefaultHeaders(uid, bearerToken);
      const response = await axios.delete(url);
  
      // console.log('The respons', response);
      if(response.status === 204){
        console.log('Deleted asmt');
        return true;
      }
  
      return false;
    }catch(error){
      console.log('API ERROR:',error);
    }
    return false;
  }


  async function deleteSubject(firebaseUser: FirebaseUserProps, subjectId:string) {

    let baseUrl = `${cpapApiEndpoint}subjects/${subjectId}`;
    let url = baseUrl;
  
    console.log('Delete URL:', url);
  
  
    const {uid, bearerToken } = firebaseUser;
    setAxiosDefaultHeaders(uid, bearerToken);
    try{
      const response = await axios.delete(url);
  
      if(response.status === 204){
        console.log('Deleted subject:', subjectId);
        return true;
      }
    } catch(err) 
    {console.log('API error at:', url)}
  }
  
  async function deleteCribsyUser(firebaseUser: FirebaseUserProps, emmauserId:string) {

    let baseUrl = `${cpapApiEndpoint}users/${emmauserId}`;
    let url = baseUrl;
  
    console.log('Delete URL:', url);
    
  
    const {uid, bearerToken } = firebaseUser;
    setAxiosDefaultHeaders(uid, bearerToken);
    try{
      const response = await axios.delete(url);
  
      if(response.status === 204){
        console.log('Deleted Cribsy User:', emmauserId);
        return true;
      }
    } catch(err) 
    {console.log('API error at:', url)}
  }

  export async function deleteAllData(firebaseUser: FirebaseUserProps, userState:any, userDispatch:any){
    if(!cpapApiEndpoint || !firebaseUser){
      return;
    }
  
    let baseUrl = `${cpapApiEndpoint}/assessments`;
    let url = baseUrl;
  
    const {uid, bearerToken } = firebaseUser;
    setAxiosDefaultHeaders(uid, bearerToken);
    let asmts:AssessmentSchemaProps[] = [];
    let subjectId:string|undefined = '';
  
  
  
    //get subject from this user's id
    let subjectForParent = await getSubjectDetails(firebaseUser, userState?._id);
    console.log('Subject found for parent:', subjectForParent);
    subjectId = subjectForParent?._id;
    console.log('subjectId:', subjectId);
  
    //what if subjectForParent is not found?
    //then, subjectId will be null or undefined. that is taken care of below

    if (subjectId == '' || subjectId == null || subjectId == undefined){    
        return;
    }

    const parentEmail = userState?.localEmail;
    console.log('Parent email:', parentEmail);

    if (parentEmail == '' || parentEmail == null || parentEmail == undefined){
        return;
    }

    const videosToDelete:string[] = [];

    try{
  
      const filter = {subjectId: subjectId};
      const asmts = await getFilteredAssesments(firebaseUser, parentEmail, JSON.stringify(filter));
        console.log('Asmts:', asmts);
        for (const asmt of asmts ?? []) {
            console.log('asmt:', asmt);
            // delete asmt
            let asmtId: any = asmt?._id;
            console.log('Delete asmtid:', asmtId);
            console.log('Asmt subject:', asmt?.subjectId);
            if (asmt?.subjectId == subjectId && (subjectId != null && subjectId != undefined)) {
                console.log('Asmt subjectid:', asmt?.subjectId);
                console.log('Subject for this parent:', subjectId);
                console.log('Deleting asmt:', asmtId);
                //
                // WARNING: This is the line that deletes the assessment
                await deleteAssessment(firebaseUser, asmtId);
                const videoUrl = asmt?.video;
                if(videoUrl != '' && videoUrl != null && videoUrl != undefined){

                    const urlArray = asmt?.resulturl ? asmt?.resulturl.split('|') : '';
                    // resulturl is a pipe separated string of urls, one for pose overlay and
                    // another for suprise.json
                    // do you want to delete the original video, which is videoUrl?
                    for (const url of urlArray){
                      videosToDelete.push(url);
                    }
                }
            }
        }
  
    } catch(err){
      console.log('API error at :', url);
    }

    finally {
        
        // delete subject
        const subjectToDelete:any = subjectId;
        console.log('Subject to delete:', subjectToDelete);
        // WARNING: This is the line that deletes the subject
        await deleteSubject(firebaseUser, subjectToDelete);
      
  
      //console.log('Deleting notifications for emmauser:', userState?.userId);
      //await deleteNotifications(firebaseUser, userState?.userId);
  
      //finally, delete cribsyuser
      if (userState?.userId != undefined && userState?.userId != null){
        console.log('Cribsy user to delete:', userState?._id);
        // WARNING: This is the line that deletes the cribsy user
        await deleteCribsyUser(firebaseUser, userState._id);
  
      }

    
      //delete videos
      try{
      for (const videoUrl of videosToDelete){
        deleteCloudVideo(firebaseUser, videoUrl);
      }
      }
      catch(err){
        console.log('Error deleting videos:', err);
      }
    }
  
  }

  export async function deleteNotifications(firebaseUser:any, emmauserId: string){


    const url = `${cpapApiEndpoint}/notifications`;
    //the api gets notifications for the user specified by the uid in the axios headers
    
    console.log('Delete notifications URL:', url);
    
  
    const {uid, bearerToken } = firebaseUser;
    setAxiosDefaultHeaders(uid, bearerToken);
    try{
  
      const response = await axios.get(url);
      let alerts = response.data;
      for (const alert of alerts){
        console.log('notification:', alert);
        //delete?
        let notId = alert.notificationId;
  
        console.log('Deleting notification:', notId);
        await deleteNotification(firebaseUser, notId);
      }
  
    }catch(err){
      console.log('Error deleting notifications:', err);
    }
  
  }

export async function deleteNotification(firebaseUser: FirebaseUserProps, notificationId: string){

  if(!cpapApiEndpoint){
    return false;
  }

  const url = `${cpapApiEndpoint}notifications/${notificationId}`;
  try{
    const {uid, bearerToken } = firebaseUser;
    setAxiosDefaultHeaders(uid, bearerToken);
    const response = await axios.delete(url);

    // console.log('The respons', response);
    if(response.status === 204){
      console.log('deleted notification:', notificationId);
      return true;
    }

    return false;
  }catch(error){
    console.log('API ERROR:',error);
  }
  return false;
}
