import React, { useState, useCallback, useEffect } from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  Grid, 
  Card,
  CardContent,
  FormControl,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Button,
  DialogActions,
  Paper,
  Snackbar,
  Divider as MuiDivider,
  TextFieldProps
} from '@material-ui/core';

import { OndemandVideo } from '@material-ui/icons';

import CloseIcon from '@material-ui/icons/Close';
import { IconButton } from '@material-ui/core';
import { spacing } from '@material-ui/system';
import {  KeyboardDatePicker } from '@material-ui/pickers';
import {DropzoneArea, DropzoneDialog} from 'material-ui-dropzone';
import { useDropzone } from 'react-dropzone';
import {isAndroid, isIOS} from 'react-device-detect';

import styled, {ThemeConsumer} from 'styled-components';

import DropZone from 'react-dropzone-uploader';
import 'react-dropzone-uploader/dist/styles.css';

import { useUserListState } from '../../context/UserListContext';

import { Autocomplete } from '@material-ui/lab';
import {getSorting, stableSort} from '../../common/tableUtilities'; 
import { getSubjectFromParentEmail } from '../../common/apiGetUtilities';
import {useUserState} from '../../context/UserContext';
import {  UserProps, AssessmentListUIProps, AssessmentPayloadProps, SubjectProps } from '../../common/entityUtilities';
import AsmtCreationProgressComponent from './components/AsmtCreationProgressComponent';
import { AssessmentSchemaProps, emptySubjectState, SubjectSchemaProps } from '../../common/mongoSchemas';
import { SaveStatusTypes } from '../../common/entityUtilities';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import useStyles from './styles';
import { useSubjectState } from '../../context/SubjectContext';
import { showAlert } from '../../common/utilities';
import { checkForStudyId } from '../../common/apiGetUtilities';
import {useTranslation} from 'react-i18next';
import { JSX } from 'react/jsx-runtime';
import { el } from 'date-fns/locale';
const Divider = styled(MuiDivider)(spacing);
// const filter = createFilterOptions();

type NewAssessmentDialogProps = {
  isOpen:boolean,
  dialogComplete:()=>void,
  parentId: string,
  parentInformation:UserProps|null,
  asmtToEdit?:AssessmentListUIProps,
  subject?: SubjectProps
}

type NewAssessmentDialogValues = {
  email:string,
  dob: Date,
  duedate?: Date,
  asmtdate:Date|null, //default asmt date is CURRENT DATE
  notes:string,
  parentemail:string,
  parentId:string,
  gestAgeWeeks:number
};

const defaultState:NewAssessmentDialogValues = {
  email:'',
  dob: new Date('2020-08-20T00:00:00.000Z'),
  duedate: new Date('2020-08-20T00:00:00.000Z'),
  asmtdate:null,
  notes:'',
  parentemail:'',
  parentId:'',
  gestAgeWeeks:0
};


export default function NewAssessmentDialog(props:NewAssessmentDialogProps):JSX.Element {

  const {t, i18n} = useTranslation();

  const classes = useStyles();
  const disableDropZoneArea = true;
  //material-ui-dropzone was causing errors/crashes when uploading large video files
  //disabling it as of Dec 15 2022
  //react-dropzone-uploader module works fine and has worked fine on Android
  //So, both iOS and Android will use that module instead of material-ui-dropzone

  const userContext = useUserState();
  const {asmtToEdit, parentId, isOpen,  dialogComplete, subject} =props;
  const [isStudyIdValid, setStudyIdValid] = useState(false);
  const [parentInformation, setParentInformation] = useState<UserProps|null>(null);

  const [videos, setVideos] = React.useState<File[]>([]);
  // This is probably duplicating something, but i don't want to spend the
  // time to unravel things for now dlp
  const cribsyUser = useUserState();
  const { allUsers } = useUserListState();
  const [currentSelectedParent, setCurrentSelectedParent] = useState<UserProps | null>(null);
  const [currentSelectedSubject, setCurrentSelectedSubject] = useState<SubjectSchemaProps | null>(null);
  const [parentEmailForSearch, setParentEmailForSearch] = useState<string|null>('');
  const [subjectIdForSearch, setSubjectIdForSearch] = useState<string|null>('');
  const subjectFromContext = useSubjectState();
  const [dialogValue, setDialogValue] = React.useState(defaultState);
  const [hasSubject, setHasSubject] = useState(false);
  const [forUpdate, setForUpdate] = useState(false);
  const [savingStatus, setSavingStatus] = useState(SaveStatusTypes.INITIALIZE);
  const [asmtToSavePayload, setAsmtToSavePayload] = useState<AssessmentPayloadProps>();
  const [asmtReadyToSave, setAsmtReadyToSave] = useState(false);
  const [isCameraDialogVisible, setCameraDialogVisible] = useState(false);
  const [files, setFiles] = useState([]);
  const [dropzoneText, setDropzoneText] = useState(t('dropzonetext',{ns:'uploadvideo'}));

  const [toasterOpen, setToasterOpen] = useState(false);
  const [snackbarDuration, setSnackbarDuration] = useState(5000);
  const [toasterMessage, setToasterMessage] = useState('Requesting new assessment');
  const [datePickerIsOpen, setDatePickerOpen] = useState(false);

  useEffect (()=>{
    // get parentInformation from parentId
    //
    setParentInformation(cribsyUser);
    console.log('subject:', subjectFromContext);

  },[parentId]);

  useEffect (()=> {
    if (isIOS){
      setDropzoneText(t('dropzonetextios',{ns:'uploadvideo'}));
    }
    else if (isAndroid){
      setDropzoneText(t('dropzonetextandroid',{ns:'uploadvideo'}));
    }

  }, [isAndroid, isIOS]);

  useEffect( () => {
    console.log('videos...:', videos?.length);
    if (videos.length > 0){
      setDropzoneText(t('fileadded',{ns:'uploadvideo'}));
      
    }
    else {
      setForUpdate(false);
      setDropzoneText(t('dropzonetext',{ns:'uploadvideo'}));
      if (isIOS){
        setDropzoneText(t('dropzonetextios',{ns:'uploadvideo'}));
      }
      else if (isAndroid){
        setDropzoneText(t('dropzonetextandroid',{ns:'uploadvideo'}));
      }
    }
  },[videos]);

  useEffect( ()=> {
    
    const setSubjectFromSearch = async ()=>{
    console.log('The parent email;,', parentEmailForSearch);
    if(parentEmailForSearch === null){
      setCurrentSelectedParent(null);
      setSubjectIdForSearch(null);
      setCurrentSelectedSubject(null);
      return;
    }

    const matchingParent = allUsers.find(user=>user.localEmail === parentEmailForSearch);
    console.log('The matching parent ', matchingParent);
    if(matchingParent){
      
      setCurrentSelectedParent(matchingParent);
     

        if (cribsyUser.firebaseUser) {
          const subject = await getSubjectFromParentEmail(cribsyUser.firebaseUser, parentEmailForSearch);
          if (subject?.length == 0) {
            console.log('No subject found for parent email:', parentEmailForSearch);
            return;
          }
          else {
            console.log('Subject found:', subject[0]);
            setSubjectIdForSearch(subject[0]._id);
            setCurrentSelectedSubject(subject[0]);
            console.log('asmtToEdit:', asmtToEdit);
          }
        }
      

    }
  }
  setSubjectFromSearch();

  }, [parentEmailForSearch]);

  useEffect ( () => {

    //console.log('Cribsy User in new asmt:', cribsyUser);

    // the following line is not being called

    if(cribsyUser.localEmail){

      checkForStudyId(cribsyUser?.firebaseUser, cribsyUser.localEmail).then (
          (value) => {
            console.log('Study Id:', value);
            if (value !== ''){
              setStudyIdValid(true);
            }
            else {
              setStudyIdValid(false);
            }
        
          }
        ).catch ( (error)=>{
          console.log('Error checking study id:', error);
          setStudyIdValid(false); 
        })
    }

  }, [cribsyUser.localEmail]);


  // Interesting, seems like this gets called when the containing component
  // gets opened
  useEffect(()=>{
    if(asmtToEdit){
      console.log('asmtToEdit data:', asmtToEdit);

      const updatedDialogValues:NewAssessmentDialogValues = {
        ...defaultState,
        parentemail: asmtToEdit.parent,
        notes: asmtToEdit.notes,
        asmtdate: asmtToEdit.date,
        dob: asmtToEdit.dob,
        duedate: subject?.duedate,
        parentId: asmtToEdit.parentId
      };
      const tempSubj = asmtToEdit.subject;
      setHasSubject(true);
      setForUpdate(true);
      setDialogValue({...dialogValue, ...updatedDialogValues});
      return;
    }

    
    // the parentinfo comes for the parent selectin/creation dialog
    // they are of type UserProps and comes for userListContext
    if(!parentInformation) return;
    const updatedDialogValues:NewAssessmentDialogValues = {
      ...defaultState,
      parentemail: parentInformation.localEmail!,
      parentId: parentInformation._id!
    };
    
    console.log('[Subject]:', subject);
    if(subject){
      setHasSubject(true);
      if(subject?.dob){
        updatedDialogValues.dob = subject.dob;
      }
      updatedDialogValues.gestAgeWeeks = subject.gestage;
      updatedDialogValues.asmtdate = null; // let the user fill it in
      console.log('[dialogValues]:', updatedDialogValues);
      console.log('Cribsy User:', cribsyUser);
    }else{
      setHasSubject(false);
    }    
    setDialogValue({...dialogValue, ...updatedDialogValues});

  },[isOpen, asmtToEdit]);

  // using the savingStatus state could be a way to 
  // get a cancel control in.
  const handleClose = () => {
    if(savingStatus==SaveStatusTypes.SAVING) return;
    console.log('Clearing data', defaultState);
    setHasSubject(false);
    setForUpdate(false);
    setDialogValue(defaultState);
    setSavingStatus(SaveStatusTypes.INITIALIZE);
    dialogComplete();
  };

  const showTutorialVideo = () => {
    const url = (i18n.language=='en') ? 'https://youtu.be/jKOm49wJ9Aw': 
        'https://youtu.be/81fX677PoHM' ;
    console.log('Opening tutorial video for language: ', i18n.language);
    window.open(url,'_blank');
   
  };

  const setVideoFromCapture = (video:any) => {
    console.log('set video from capture');
    const videoSuffix = 1;
    let current_datetime = new Date();
    video.lastModifiedDate = current_datetime;
    let formatted_date = current_datetime.getFullYear() + '-' + (current_datetime.getMonth() + 1) + '-' + current_datetime.getDate() + ' ' + current_datetime.getHours() + ':' + current_datetime.getMinutes() + ':' + current_datetime.getSeconds(); 
    video.name = 'recording-' + formatted_date + '-' + videoSuffix;
    setVideos([video]);

  };

  const onDrop = useCallback( (acceptedFiles: React.SetStateAction<File[]>) => {
    setVideos(acceptedFiles);
  },[]);


  const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop});

  useEffect(()=>{
    // console.log('Saving change', savingStatus);
    if(savingStatus === SaveStatusTypes.DONE){
      //To avoid oddities with users having assessments with videos
      //that don't belong to them clear out the selected video ids.
      handleClose();
    }
  },[savingStatus]);

  // const handleDropzoneUpload = async () => {
  //   // save all the files
  //   console.log('Save all files', videos);
  // };



  const handleToasterClose = (event:any,reason:any) => {
    console.log('toaster');
    if (reason === 'clickaway') {

      setToasterOpen(false);
      return;
    }
    setToasterOpen(false);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (videos && videos.length > 0){
      setToasterMessage(t('pleasewait',{ns:'uploadvideo'}));
      setToasterOpen(true);
      setSnackbarDuration(10000);
    }
    let localAssessor = userContext?.firebaseUser?.email;
    console.log('[handleSubmit] for video upload');
    if(!userContext?.firebaseUser?.email || !subject?._id){
      // the user could have used phone auth, in which case firebaseUser won't have an email
      if (!userContext?.userId){
        console.log('emmauser:', userContext?.userId);
        return;
      }
      else{
        console.log('saving new asmt for:', userContext?.firebaseUser);
        localAssessor = userContext?.localEmail;
      }
      
    }
    // should be of type AssessmentPayloadProps
    const assessmentSavePayload:AssessmentPayloadProps = {
        date: dialogValue.asmtdate,
        subjectDueDate: dialogValue?.duedate,
        subjectId: subject?._id,
        notes: dialogValue.notes,
        // additionalProp1:{notes:dialogValue.notes},
        videosForUpload: videos,
        subjectDob: dialogValue.dob,
        parentEmail: dialogValue.parentemail
    };


    // currentSelectedParent is the parent that was selected
    // subjectIdForSearch is the subject belonging to that parent 
    if (cribsyUser.authLevel >= 5 && currentSelectedParent && subjectIdForSearch){
      // this is an admin user and a parent was selected AND the subject was found
      // this video is being saved for a specific parent and subject
      assessmentSavePayload.parentEmail = currentSelectedParent.localEmail || '';
      assessmentSavePayload.subjectId = subjectIdForSearch;
      
      assessmentSavePayload.subjectDob = currentSelectedSubject?.dob;
      assessmentSavePayload.subjectDueDate = currentSelectedSubject?.duedate;
    }

    console.log('Asmt payload to save:', assessmentSavePayload);

    

    if(asmtToEdit){
      assessmentSavePayload.assessmentId = asmtToEdit.assessmentId;
    }
    
    setSavingStatus(SaveStatusTypes.SAVING);
    
    // Set the save payload, and let go since the 
    // the setter is connected AsmtCreationProgressCompoenent 
    // that does the upload
    
    setAsmtToSavePayload(assessmentSavePayload);
    setAsmtReadyToSave(true);
  };


  const handleDropZoneChangeStatus = ( meta:any, status:any) => {
    console.log('Dropzone changestatus');
    console.log('Status:',status);
    console.log('Meta:',meta);
    console.log('File:', meta.file);
    console.log('File name:', meta.file.name);
    if (status == 'removed'){
      setVideos([]);
    }
    if (status == 'done'){
     let selected = [meta.file];
     console.log('Setting selected video');
     setVideos(selected);
    }
  };

  return (     
    <Dialog
      aria-labelledby="form-dialog-title"
      onClose={handleClose} //closes on click away
      open={isOpen}
      fullScreen
    >
      <form onSubmit={handleSubmit}>
        <DialogTitle id="form-dialog-title">
            Create/update assessment
        </DialogTitle>
        <AppBar className={classes.paper}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              disabled={savingStatus===SaveStatusTypes.SAVING}
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" component="div">
              {t('title',{ns:'uploadvideo'})}
            </Typography>
                  
            <IconButton color="inherit"
              onClick={showTutorialVideo}
            >
              <OndemandVideo>

              </OndemandVideo>
            </IconButton>
           
          </Toolbar>
        </AppBar>
        <DialogContent className={classes.paper}>
            <Card  >
              <CardContent>

              <Grid
            container
            spacing={3}
          >
            <Grid
              item
              xs={12} sm={12} md={12}
            >
              {savingStatus===SaveStatusTypes.INITIALIZE?(
                <React.Fragment>
                  <DialogContentText
                    color="secondary"
                    variant="h6"
                  >
                  </DialogContentText>
                
                  <Grid container>
                    {(isIOS && !disableDropZoneArea) && 
                      <Grid item md={12} sm={12} xs={12}>
                        <DropzoneArea
                        maxFileSize={1000000000}
                        onChange={(files:any) => {
                          console.log('Setting video file:',files);
                          console.log('hasSubject:', hasSubject);
                          console.log('SavingStatus:', savingStatus);
                          console.log('Num videos:',videos.length);
                          console.log('forUpdate:',forUpdate);


                          if(files && files.length > 0){
                            console.log('Video file non null');
                            setVideos(files);
                          }
                        }}
                        //onChange for DropzoneArea component
                        dropzoneText={dropzoneText}
                        acceptedFiles={['video/*']}
                        showAlerts={true}
                        onAlert={(message:string) => {
                          console.log('Message from dropzone:', message);
                        }}
                        showPreviews={true}
                        showPreviewsInDropzone={false}
                        useChipsForPreview
                        previewGridProps={{container: { spacing: 1, direction: 'row' }}}
                        onDrop={(files,event)=>{
                          console.log('Event:',event);
                        }}
                        // showPreviewsInDropzone={true}
                        
                        />
                      </Grid>
                    }
                    { cribsyUser.authLevel >= 5  &&
                      <Grid  item md={12} sm={12} xs={12}>
                      <Card><CardContent>
                      <Grid item md={12} sm={12} xs={12}>
                        
                      
                        <Typography variant='h6' color='secondary'>
                          {t('selectparent',{ns:'uploadvideo'})}:
                          </Typography>
                        
                      </Grid>
                      <Grid item md={8} sm={8} xs={6}>
                        
                        <Autocomplete
                          autoHighlight
                          blurOnSelect
                          getOptionLabel={(option: { localEmail: any; })=> (option && option.localEmail)?option.localEmail:'unknown'}
                          // { // functionally equivalent, gads all this short hand
                          //   return (option && option.localEmail)?option.localEmail:'unknown';
                          // }}
                          handleHomeEndKeys
                          id="parent-email-selector"
                          onChange={(event: any, newValue: { localEmail: React.SetStateAction<string>; } | null) => {
                            console.log('Selected parent:',newValue);
                            if(newValue && newValue.localEmail){
                              setParentEmailForSearch(newValue.localEmail as string | null);
                            }else{
                              setParentEmailForSearch(null);
                            }
                          }}
                          options={stableSort(allUsers,getSorting('asc','localEmail'))}
                          renderInput={(params: JSX.IntrinsicAttributes & TextFieldProps) => (
                            <TextField
                              {...params}
                              label={t('parentemail',{ns:'uploadvideo'})}
                              variant="outlined"
                            />
                          )}
                          selectOnFocus
                          style={{ width: 300 }}       
                        />
                        
                      </Grid>
                      
                      <Grid item md={4} sm={4} xs={6}>

                        <Typography variant='h6' color='secondary'>
                          {t('selectedparent',{ns:'uploadvideo'})}: 
                        </Typography>
                        {currentSelectedParent?.localEmail}
                      </Grid>
                      </CardContent></Card>
                      </Grid>

                      

                    }
                    {(isAndroid || isIOS) && 
                      <Grid item md={12} sm={12} xs={12}>
                        <Paper>
                          <Typography variant='h5' color='primary'>
                            {t('step1title',{ns:'uploadvideo'})}:
                          </Typography>
                          <Typography gutterBottom variant='body2'>
                            {dropzoneText}
                          </Typography>
                        </Paper>
                      </Grid>
                    }
                    {(isAndroid || isIOS) &&
                      <Grid item md={12} sm={12} xs={12}>
                        <DropZone
                          accept='video/*'
                          multiple={false}
                          maxFiles={1}
                          maxSizeBytes={1000000000}
                          onChangeStatus={handleDropZoneChangeStatus}
                          inputContent={t('step1text',{ns:'uploadvideo'})}
                        />
                      </Grid>
                    }
                    {/* what if it is a straight up browser? */}
                    {
                      !isAndroid && !isIOS &&
                      <Grid container>
                        <Grid item md={12} sm={12} xs={12}>
                          <Paper>
                            <Typography variant='h6' color='primary'>
                              {t('step1title',{ns:'uploadvideo'})}:
                            </Typography>
                            <Typography gutterBottom variant='body2'>
                              {dropzoneText}
                            </Typography>
                          </Paper>
                        </Grid>
                        <Grid item md={12} sm={12} xs={12}>
                        <DropZone
                          accept='video/*'
                          multiple={false}
                          maxFiles={1}
                          maxSizeBytes={1000000000}
                          onChangeStatus={handleDropZoneChangeStatus}
                        />
                      </Grid>
                    </Grid>

                    }
                   
                  </Grid>
                </React.Fragment>
              ):(

                <AsmtCreationProgressComponent
                  asmtSaveValues={asmtToSavePayload}
                  // closeParent={handleClose}
                  setSavingStatus={setSavingStatus}
                />
              )}             
            </Grid>

            <Grid item justify='flex-end'>
             
             <Button 
               variant='contained'
               color = 'secondary'  
               type='submit'   
               disabled={ 
                 !hasSubject || savingStatus===SaveStatusTypes.SAVING ||
                 (/*forUpdate && */ videos.length ===0) || (dialogValue?.asmtdate==null)
               }
             >
               {forUpdate?t('update',{ns:'uploadvideo'}): t('save',{ns:'uploadvideo'})} 
             </Button>
           </Grid>

            <Grid item xs={12}>
              { (dialogValue?.asmtdate == null && videos.length > 0)  ? (
                <Typography variant="h6" color="primary">
                 {t('step2alt',{ns:'uploadvideo'})}.
                </Typography>): 
              (
              <Typography variant="h5" color="primary">
              {t('step2title',{ns:'uploadvideo'})}:
              </Typography>)}
            </Grid>
            <Grid
              item
              xs={6}
            >
              <KeyboardDatePicker

                KeyboardButtonProps={{
                  'aria-label': 'change date',
                  onFocus: e => {
                    setDatePickerOpen(true);
                  }
                }}
                InputProps={{
                  onFocus: () => {
                    setDatePickerOpen(true);
                  }
                }}
                color="primary"
                disabled={
                  forUpdate|| savingStatus===SaveStatusTypes.SAVING
                }
                format={i18n.language=='en'? "MM/dd/yyyy": "dd/MM/yyyy"}
                label={t('datelabel',{ns:'uploadvideo'})}
                margin="normal"
                onChange={(event) => {
                  setDialogValue({ ...dialogValue, asmtdate: event as Date});
                  console.log('Closing datepicker');
                  setDatePickerOpen(false);
                }
                }
                value={dialogValue.asmtdate}
                // defaultValue={null}
                
               //  onClick={()=>{setDatePickerOpen(true);}}
               // open={datePickerIsOpen}
              />
            </Grid>


            <Grid item md={12} sm={12} xs={12}>
              <FormControl
                fullWidth
                // mb={3}
              >
                <TextField 
                  id="notes"
                  color='primary'
                  label={t('step3notes',{ns:'uploadvideo'})}  
                  multiline
                  onChange={(event) => setDialogValue({ ...dialogValue, notes: event.target.value })}
                  placeholder={t('step3placeholder',{ns:'uploadvideo'})}
                  // rows={3}
                  // rowsMax={2}
                  value={dialogValue.notes}
                />
              </FormControl>
            </Grid>


        { !isStudyIdValid &&
            <Grid container item md={12} sm={12} xs={12}>
                          <FormControl>
                          <Typography variant='h5' color='secondary'>
                            {t('studymsgtitle',{ns:'uploadvideo'})}:
                          </Typography>
                          <Typography  variant='caption'>
                           {t('studymsg',{ns:'uploadvideo'})}
                          </Typography>
                          </FormControl>
                        
            </Grid>
            }

            </Grid>
            </CardContent>            
            </Card>


        </DialogContent>
        

      </form>

    
    <Snackbar
        autoHideDuration={snackbarDuration}
        message = {toasterMessage} 
        onClose={handleToasterClose}
        open={toasterOpen}
      />
    </Dialog>
  );
}
