import React, {useState, useEffect} from 'react';

// import PropTypes from 'prop-types';
import {
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  // DialogContentText,
  DialogActions,
  // Divider,
  Button,
  MenuItem,
  Typography,
  // FormControl,
  Grid,
  FormGroup,
} from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

// import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';

import {  KeyboardDatePicker } from '@material-ui/pickers';
import {
  emptyUserState, UserProps,
} from '../common/entityUtilities';

import {
  // useUserListState,
  useUserListDispatch,
  UserListTypes,
  useUserListState
} from '../context/UserListContext';

import {
  useUserState,
  useUserDispatch,
  UserActionTypes
} from '../context/UserContext';

import { useTranslation } from 'react-i18next';
import { checkForStudyId } from '../common/apiGetUtilities';
import { genders } from '../common/staticdata';

import { SubjectInfo } from './SubjectInfoDialog';
import {
  setUserInfo
} from '../common/apiSetUtilities';

import {
  convertUserDataToUserPropType
} from '../common/entityUtilities';

import { 
  CpapUserSchemaProps
} from '../common/mongoSchemas';

type UIPropsForNewUserDialog = {
  email?:string,
  userId?:string,
  firstname:string,
  lastname:string,
  babyname:string,
  gender:string,
  dob:Date,
  org?: string,
  duedate:Date|null,//needs to be nullalbe in ui but null== Date(0) for db
  gestAgeDays:number|undefined, //gest age days, but gets displayed weeks/days
  notes:string,
  subjectId?:string
  // for admins
  signature?:string
};

const emptyParentUIState:UIPropsForNewUserDialog = {
  email:'',
  userId:'',
  firstname:'',
  lastname:'',
  babyname:'',
  gender:'F',
  org:'',
  dob:new Date(),
  duedate:null,//new Date(),
  gestAgeDays:undefined,//40*7, //40 weeks * 7 days
  notes:''
};

export type UserInfoDialogProps = {
  isOpen:boolean,
  dialogComplete:()=>void,
  allOrgs:any[],
  userInformation:UserProps|null

};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .MuiTextField-root': {
        margin: theme.spacing(2),
        width: 200,
      },
    },
  }),
);
//https://dev.to/hibaeldursi/creating-a-contact-form-with-validation-with-react-and-material-ui-1am0
// This dialog is so ugly and disorganized...
// hopefully it gets refactored out. Need the UIPropsForNewParentDialog
// makes this particularly ugly
export const UserInfoDialog = (props:UserInfoDialogProps):JSX.Element=> {

  const {t, i18n} = useTranslation(); 
  const classes = useStyles();
  const [subjectInfoOpen, setSubjectInfoOpen] = useState(false);
  // userInformation is UserProps
  const {isOpen, dialogComplete, userInformation, allOrgs}  = props;
  
  const cribsyUser = useUserState();
  const userListDispatch = useUserListDispatch();
  const userDispatch = useUserDispatch();
  const userList = useUserListState();
  // The allusers context populates the selections, but they 
  // are full Userprops, not just emails. which means?
  // i think the combobox has all the data about the selected
  // user, maybe
  // const { allUsers } = useUserListState();
  // const [mainValue, setMainValue] = useState(null);
  // const [open, toggleOpen] = useState(false);
  const [dialogValue, setDialogValue] = useState(emptyParentUIState);
  const [displaySubjectInformation, setDisplaySubjectInformation] = useState(true);
  // this can probably be replaced by the emmaUserId
  const [isNewUser, setIsNewUser] = useState(true);
  const [calculatedGestAgeString, setCalculatedGestAgeString] = useState<string|undefined>();
  // hackish
  const emailSet = new Set(userList.allUsers.map((userInfo)=>userInfo.localEmail));
  const [errors, setErrors] = useState<any>({});
  const [studyId, setStudyId] = useState<string|undefined>('');

  // useEffect(()=>{
  //   console.log('error', errors);
  // },[errors]);

  useEffect(()=>{
    const setFunction = async ()=>{
      // The userinfo is of type UserProps
      if(userInformation === null) {
        console.log('Attempting a dialog reset?');
        return;//hacky way of resetting dialog which stopped working
      }
      if(!userInformation){
        setDisplaySubjectInformation(true);
        setIsNewUser(true);
        setDialogValue(emptyParentUIState);
        return;      
      }
      const displaySubjectInfo = (userInformation.authLevel <5);
      setDisplaySubjectInformation(displaySubjectInfo);
      setIsNewUser(false);
      console.log('userInformation in [useEffect]:', userInformation);
      let dialogValues:UIPropsForNewUserDialog =  {...emptyParentUIState, 
        firstname:userInformation.firstname,
        lastname:userInformation.lastname,
        email:userInformation.localEmail,
        userId:userInformation.userId
        // authLevel:userInformation.authLevel
      };
      console.log('Setting dialogValues:', dialogValues);
      setDialogValue(dialogValues);

      if (userInformation.localEmail){
        const val = await checkForStudyId(cribsyUser?.firebaseUser, userInformation.localEmail);
        if (val !== undefined && val !== ''){
          console.log('Found StudyId:', val);
          setStudyId(val);
        }
      }
    }
    setFunction();
    
   
  },[userInformation]);
  



  const updateUIWithValues = (createNewUser: boolean, emmaUserId: string | undefined, 
    userPropertiesMongoDb: Partial<CpapUserSchemaProps>) =>{

    const newPropsForUser = convertUserDataToUserPropType(userPropertiesMongoDb);
    // hope and pray the createNewUser is accurate. The userProperties are only what got update
    // maybe it should include the id of what was update.
    if(createNewUser){
      const newEmmaUser = {...emptyUserState, ...newPropsForUser };
      userListDispatch({type:UserListTypes.ADD_USERS, allUsers:[newEmmaUser]});
    }else{
      // updating a user that exists
      console.log('Updating with', newPropsForUser);
      console.log('EmmaUserId:', emmaUserId); // this is the user we are updating
      console.log('UserState:', cribsyUser); // this is the user that is logged in
      if(emmaUserId === cribsyUser.userId){
        userDispatch({type:UserActionTypes.UPDATE, ...newPropsForUser});
        newPropsForUser.userId = emmaUserId;
        // Hack/bad design: need to attach the local email for the update until next pass
        userListDispatch({type:UserListTypes.UPDATE_USERS, allUsers:[newPropsForUser]});
      }else{
        newPropsForUser.userId = emmaUserId;
        // Hack/bad design: need to attach the local email for the update until next pass
        userListDispatch({type:UserListTypes.UPDATE_USERS, allUsers:[newPropsForUser]});
      }
    }
  };
  


  const handleClose = () => {
    // new way of reseting dialog state when closing.
    setErrors({});
    setDialogValue(emptyParentUIState);
    dialogComplete();
  };


  const handleDateClose = () => {
    setSubjectInfoOpen(false);
  };

  const handleBabyInfo = () => {
    setSubjectInfoOpen(true);
  };
  
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    console.log('[handleSubmit] dialogValue:', dialogValue);
    console.log('[handleSubmit] userInformation:', userInformation);

    // Copied from UserSettings component
    const mongoUserValuesToUpdate:Partial<CpapUserSchemaProps> = {};//
    // A checkbox in the ui can give the option though
    // the new users is not registered, and hopefully the email matches
    if(isNewUser){
      mongoUserValuesToUpdate.authLevel = -1;
      mongoUserValuesToUpdate.email = dialogValue.email;
    }
    mongoUserValuesToUpdate.firstname = dialogValue.firstname;
    mongoUserValuesToUpdate.lastname = dialogValue.lastname;
    
    // how does the api deal with undefined.

  

    // The user that is saving things
    const firebaseUser = cribsyUser.firebaseUser;
    // hmm wont this naturally be undefined?
    let userToUpdateId = userInformation?._id;
    if(isNewUser) userToUpdateId = undefined;
    // Save the new user, gads we really really really needs tests...
    // gads this save is not robust...
    setUserInfo(firebaseUser, userToUpdateId, mongoUserValuesToUpdate)
      .then(storedUserData=>{ // Stored data is CpapUserSchemaProps
   
        // Add the subject/baby info
        // In theory this only gets called on a new parent ie
        // a parent with no subject so no need for collision checks.
        // This is the SubjectSchemaProps
        if(userToUpdateId === undefined && storedUserData.userId ){
          userToUpdateId = storedUserData.userId;
        }
        
         
        updateUIWithValues(isNewUser, userToUpdateId, storedUserData);
        
      });
    handleClose();
  };

  //Ugh, this UI should to be split/updated for two components, one for user and the
  // other for parents
  return (

    <Dialog
      aria-labelledby="form-dialog-title"
      onClose={handleClose}
      open={isOpen}
    >
      <form onSubmit={handleSubmit}>
        <DialogTitle id="form-dialog-title">          
          {displaySubjectInformation?'Family information':'Update user'}
        </DialogTitle>
        <DialogContent className={classes.root}>
          <div>
            <Typography
              color="secondary"
              variant="h6"
            >
              {displaySubjectInformation?'Family info':'User info'}
            </Typography>
            <TextField
              autoFocus
              error = {errors.email}
              fullWidth
              helperText = {errors.email}
              id="parentemail"
              inputProps={{
                readOnly: !isNewUser
              }}
              label="Email"
              margin="dense"
              onChange={(event) => 
              {
                const emailVal = event.target.value;
                if(emailSet.has(emailVal)){
                  setErrors({email:'Email is already registered.'});
                }else{
                  const {email, ...rest} = errors;
                  setErrors(rest);
                }
                setDialogValue({ ...dialogValue, email: emailVal });
              }}
              style={{ width: '80%' }}
              type="email"
              value={dialogValue.email}
            />
            <FormGroup
              aria-label="position"
              row
            >
              <TextField
                autoFocus                
                id="parentfirstname"
                label="First name"
                margin="dense"
                onChange={(event) => setDialogValue({ ...dialogValue, firstname: event.target.value })}
                type="text"
                value={dialogValue.firstname}
              />
              <TextField
                autoFocus                
                id="parentlastname"
                label="Last name"
                margin="dense"
                onChange={(event) => setDialogValue({ ...dialogValue, lastname: event.target.value })}
                type="text"
                value={dialogValue.lastname}
              />
              <TextField
                InputProps={{
                readOnly: true
                }}
                fullWidth
                InputLabelProps={{ shrink: true }} 
                id="studyid"
                label={t('studyidlabel',{ns:'usersettings'})}
                
                value={studyId}
              />


            </FormGroup>
            

            
          </div>         

        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={handleClose}
          >
              Close
          </Button>
          <Button
            color="primary"
            disabled={Object.keys(errors).length > 0 || !dialogValue.email}
            type="submit"
          >
            {isNewUser?'Create':'Update'}              
          </Button>

          <Button
                color="primary"
                variant='outlined'
                onClick={handleBabyInfo}
              >
                  ...
          </Button>
          {userInformation && (
            <SubjectInfo isOpen={subjectInfoOpen} handleClose={handleDateClose} parentId={userInformation._id}/>
          )}
                              
        </DialogActions>
      </form>
    </Dialog>
  );
};
