// Types exported for tests
export type WeeksAndDays = {
    weeks:number,
    days:number,
    totalDays?:number
  }
  export type MonthsAndDays = {
    months:number,
    leftOverDays:number
    monthsDecimal?:number
    // needed to avoid too many round trip estimation errors
    totalDays?:number
  }
  export const calculateAgeMonthDaysForAimsReport = (asmtDate:Date, dob:Date, 
    dueDate:Date|undefined = undefined, gestAgeDays:number|undefined = undefined)
  :MonthsAndDays[] =>{
    if(!dueDate && !gestAgeDays){
      return [];//maybe throw an error?
    }
    // calculate normal age
    const exactAgeMonthDay = calculateExactMonthDayDifference(dob, asmtDate);
    if(!exactAgeMonthDay) return [];//maybe throw an error?
  
    // check for age corrections  
    const maxDays = 42*7;
    const premiCutoffWeeks = 38;
    const minDays = premiCutoffWeeks*7; // premaature cut off 38 weeks
    const daysInFullTerm = 40*7;
    let correctedForGestAge = -1;
    // actually kind of funny. The idea is to ignore gestage ===0 because of a bug
    // but the if statement already did that accidentally.
    if(gestAgeDays){
      if(gestAgeDays < minDays) correctedForGestAge = gestAgeDays;
    }
  
    let correctedForDueDate = -1;
    if(dueDate){
      const calcedGestAge = calculateGestAgeFromExpected(dueDate, dob);
      if(calcedGestAge.weeks < 38 && calcedGestAge.totalDays){
        correctedForDueDate = calcedGestAge.totalDays;
      } 
    }
    if(correctedForGestAge === -1 && correctedForDueDate === -1){
      return [exactAgeMonthDay];
    }
    let gestAgeToUseDays = correctedForDueDate;
    if(correctedForGestAge !== -1){
      gestAgeToUseDays = correctedForGestAge;
    }
  
    const daysEarly = daysInFullTerm - gestAgeToUseDays;
  
    const adjustedDob = new Date(dob);
    // console.log('Gestage', daysEarly);
    // instead of subtracting from age, add to days to bday and 
    // recalculate the exact age.
    adjustedDob.setDate(adjustedDob.getDate() + daysEarly);
    // console.log('Gestage dob', dob);
    // console.log('Gestage adjdob', adjustedDob);
    const correctedAgeMonthDay = calculateExactMonthDayDifference(adjustedDob, asmtDate);
    // console.log('Gestage age', exactAgeMonthDay);
    // console.log('Gestage agec', correctedAgeMonthDay);
      
    // const correctedAgeDays = exactAgeMonthDay.totalDays - daysEarly;
    // const estCorrectedAge = getEstimatedMonthTimeFromDays(correctedAgeDays);
    if(correctedAgeMonthDay)
      return [exactAgeMonthDay, correctedAgeMonthDay];
    else{
      // should probably return some sort of error here
      return [exactAgeMonthDay];
    }
  };  
  
  export const calculateExactMonthDayDifference = (startDate:Date, endDate:Date):MonthsAndDays|undefined => {
    //https://stackoverflow.com/questions/2536379/difference-in-months-between-two-dates-in-javascript
  
    if(startDate > endDate) {
      // console.log('Bad days');
      return;
    }
    // expected output: false;
    let months = (endDate.getFullYear() - startDate.getFullYear()) * 12;
    months -= startDate.getMonth();
    months += endDate.getMonth();
    // const end = endDate.getDate();
    // const start = startDate.getDate();
    // console.log(start, 'and end', end);
    let days = endDate.getDate() - startDate.getDate();
    // console.log(months,'and days', days);
    if(days<0){
      months-=1;
      //  creating a new Date with negative days/months moves the entire date back that
      // value.
      const newEnd = new Date(endDate.getFullYear(), endDate.getMonth(), days);
      days = newEnd.getDate();
    }
    
    // console.log(months,'Months days', days);
    const totalDays = getElapsedNumberOfDays(startDate, endDate);
    return {months:months,leftOverDays:days, totalDays:totalDays};  
    
  };
  export const calculateEstimatedMonthAge = (startDate:Date, endDate:Date):MonthsAndDays =>{
    const dayDifference = getElapsedNumberOfDays(startDate, endDate);
    return getEstimatedMonthTimeFromDays(dayDifference);
  };
  
  export const dateMinusDays = (gestEndDate:Date, estimatedGestTimeDays = (40*7)):Date=>{
    const startDate = new Date(new Date(gestEndDate).toUTCString());
    const startDateRaw = startDate.setDate(startDate.getDate() - estimatedGestTimeDays);
    return new Date(startDateRaw);
  };
  
  export const calculateGestAgeFromExpected = (dueDate:Date, dob:Date):WeeksAndDays=>{
    const normalGestDays = 40*7;
    const bDate = new Date(new Date(dob).toUTCString());
    // use a combination of setDate and getDate +- the number of days
    // on the temporary date object, then asign it to our variable
    const startDateRaw = dateMinusDays(dueDate, normalGestDays);
    const startDate = new Date(startDateRaw.toUTCString());
  
    const calcGestDays = getElapsedNumberOfDays(bDate, startDate);
    console.log('Elapsed days:', calcGestDays)
    const daysWeeks = calculateWeeksAndDaysFromDays(calcGestDays);
    daysWeeks.totalDays = calcGestDays;
    console.log('daysweeks:',  daysWeeks);
    return daysWeeks;
  };
  
  export const calculateDaysFromWeeksAndDays = (weekAndDays:WeeksAndDays):number => {  
    return weekAndDays.days + 7*weekAndDays.weeks;
  };
  export const estimateDaysFromMonthAndDays = (monthAndDays:MonthsAndDays):number => {
    return monthAndDays.months*30+monthAndDays.leftOverDays;
  };
  export const calculateWeeksAndDaysFromDays = (numberOfDays:number):WeeksAndDays => {
    // console.log('Calculation', numberOfDays);
    const result = {} as WeeksAndDays;
    result.days = numberOfDays%7;
    result.weeks = Math.floor(numberOfDays/7);
    result.totalDays = numberOfDays;
    return result;
  };
  
  export function getElapsedNumberOfDays(startDateString:Date, endDateString:Date):number {
    // a hacky way to making dates utc, especially with dates that come for mui time picker
    const date1 = new Date(new Date(startDateString).toUTCString());
    const date2 = new Date(new Date(endDateString).toUTCString());
  
    // One day in milliseconds
    const oneDay = 1000 * 60 * 60 * 24;
  
    // Calculating the time difference between two dates
    const diffInTime = date1.getTime() - date2.getTime();
  
    // Calculating the no. of days between two dates
    const diffInDays = Math.round(diffInTime / oneDay);
  
    return Math.abs(diffInDays);
  };
  
  // const getExactMonthDaySplitFromStartAndDayCount(startDate:Date, numberOfDays:number)
  //   :MonthsAndDays=>{
  
  // }
  
  export function getEstimatedMonthTimeFromDays(numberOfDays:number):MonthsAndDays {
    const years = Math.floor(numberOfDays / 365);
    const months = Math.floor(numberOfDays % 365 / 30);
    const days = Math.floor(numberOfDays % 365 % 30);
  
    //[years, months, days].join(':');
  
    const monthDec = years*12+months+(days/30);
  
    return {months:(years*12+months), leftOverDays:days, 
      monthsDecimal:monthDec, totalDays:numberOfDays};
  }


export const prettifyMonthDay = (month:number, day:number, useWeeks = false):string =>{

    let unit = 'month';
    if(useWeeks) unit = 'week';
    const ageString=`${month} ${month===1?`${unit}`:`${unit}s`}, ${day} ${day===1?'day':'days'}`;
    return ageString;
     
  };