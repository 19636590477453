import { firebaseStorage } from "../firebaseConfig";
import {Dialog, PromptResult} from '@capacitor/dialog';
import {Toast} from '@capacitor/toast';

import { AssessmentPayloadProps, AssessmentCreationFeedBackUIProps, AssessmentListUIProps, FirebaseUserProps } from "./entityUtilities";
import {initiateProcessing, setAssessment, setVideo} from './apiSetUtilities';
import { AssessmentSchemaProps } from "./mongoSchemas";
import { refreshToken } from "../context/UserContext";
import { getAssesmentById, getLatestAssesment, getSubjectFromId } from "./apiGetUtilities";
import { FastRewind } from "@material-ui/icons";

import { useTranslation } from "react-i18next";
import { VideoFile } from "@mui/icons-material";

export const showDialog = async(title:any,message:any,oktitle:any, canceltitle:any)=> {
const {value} = await Dialog.confirm({
    title: title,
    message: message,
    okButtonTitle: oktitle,
    cancelButtonTitle: canceltitle
});

return value;

};

export const showAlert = async (title: any, message:any, buttonTitle: any):Promise<boolean> =>{
await Dialog.alert( {title: title, message: message, buttonTitle: buttonTitle} );
return true;
};
  
export const showPrompt = async (title: any, message: any): Promise<PromptResult> => {

const {value, cancelled} = await Dialog.prompt({
    title: title,
    message: message
});
const result: PromptResult = {value,cancelled};

return result;

};


export const showToast = async (msg: string) => {

    console.log('Showing toast');
    await Toast.show({
      text: msg
    });
  };

export const checkQuota = async (firebaseUser:FirebaseUserProps|undefined, subjectId: string|undefined):Promise<boolean> => {

  if(firebaseUser){
    const latest:AssessmentSchemaProps|undefined = await getLatestAssesment(firebaseUser, subjectId);
    console.log('Latest asmt:', latest);
    if (latest == undefined) {
      return true; // no asmts for this subject yet
    }
    if(latest?.createdAt){
      const asmtUpdatedDate = new Date(latest?.createdAt);
      const currentDate = new Date(Date.now());
      const hours = Math.abs(asmtUpdatedDate.getTime() - currentDate.getTime()) / 3600000;
      console.log('Hours elapsed:', hours);
      if (hours < 24.0){
        return false;
      }
      else {
        return true;
      }
    }

    return false;

  }
  return false;
}

export async function createAssessmentFromDataSomeFeedback(firebaseUser:FirebaseUserProps|undefined, 
  asmtCreationPayload:AssessmentPayloadProps, setStatus:(val:any)=>void, t: any):Promise<void>{

  if(!firebaseUser) return undefined;
  console.log('Create Assessment with feedback:', asmtCreationPayload);
  const subjectOwnerId = firebaseUser.uid;
  const videoUploadTasks = await uploadFilesToFirebaseWithFeedBack(firebaseUser, subjectOwnerId,
    asmtCreationPayload.videosForUpload, setStatus);
  const totalVideos = asmtCreationPayload.videosForUpload.length;
  // we be done
  let data:AssessmentCreationFeedBackUIProps = {
    bytesTransferred:0,
    totalSize:0,
    fileName: '',
    videoTotal:totalVideos,
    currentVideoCount:totalVideos,

    genericMessage:t('processing',{ns:'utils'})
  };
  setStatus(data);
  // For all the promises at once use this...
  // const videoUploadTasks = await Promise.all(videoUploadPromises);
  // Uploading is done, take the data and create the assessment

  const fullUrl = await firebaseStorage.ref(videoUploadTasks[0]?.metadata.fullPath).getDownloadURL();
  console.log('Full URL of uploaded vid:', fullUrl);
  
  const asmtDbPayload:AssessmentSchemaProps = {
    date:asmtCreationPayload.date,
    subjectId:asmtCreationPayload.subjectId,
    video: fullUrl
  };

  // let asmtData = <AssessmentSchemaProps>{};


  if (!asmtCreationPayload.assessmentId) {
    const asmtData = await setAssessment(firebaseUser, asmtDbPayload) as AssessmentSchemaProps;
    console.log('Asmt created:', asmtData);
    if(!asmtData){
      console.log('Failure with asmt');
      //hmm failure
      return undefined;
    }
    else {
      console.log('Created asmt on API:', asmtData);
      // New assessment created...
      const subjectDetails = await getSubjectFromId(firebaseUser, asmtData?.subjectId);
      console.log('Subject details:', subjectDetails);
      let duedate = subjectDetails?.duedate;
      console.log('Duedate:', duedate);
      let videodate = asmtData.date;

      if (duedate != null && videodate != null){
        const hoursInWeek = 7 * 24 ;
        const weeks =  (new Date(videodate).getTime() - new Date(duedate).getTime()) / (3600000 * hoursInWeek);
        let adjustedWeeks = weeks < 0? 0: weeks;
        console.log('Weeks at video time:', adjustedWeeks);
        adjustedWeeks = Math.floor(adjustedWeeks+0.49);
        console.log('Rounded Weeks at video time:', adjustedWeeks);
        let newasmtData = {...asmtData, additionalFlags: {"age": adjustedWeeks}};

        // initiate processing
        console.log('Initiate process with:', newasmtData);
        const result = initiateProcessing(firebaseUser, newasmtData);
        console.log('Kicked off processing:', result);

      }
    }
  }
  else{
   console.log('Existing assessment?');
  }


  let count = 1;
  data = {
    bytesTransferred:1,
    totalSize:1,
    fileName: '',
    videoTotal:count,
    currentVideoCount:0,  
    genericMessage:t('asmtcomplete',{ns:'utils'})
  };
  setStatus(data);
  // hmm is this asmt for UI ever used? seems like once this 
  // function returns a full reset is done on the list
  // and the entities are all created. Will keep around for future
  // optimization options 
  return ;
}


//
// Upload videos, create observations, create assessment
// If assessment payload contains an assessmentId, then, don't create an assessment -- just do the rest
//
async function uploadFilesToFirebaseWithFeedBack(firebaseUser:FirebaseUserProps|undefined,
  subjectOwnerId:string, videosForUpload:any[],
  setStatus:(val:any)=>void)
  :Promise<firebase.default.storage.UploadTaskSnapshot[]>{
    
  if(!firebaseUser) return [];
  console.log('Create Assessment with feedback');
  await refreshToken(firebaseUser);
  const bucketPath = '__videos';
  const baseBucketPath = bucketPath + '/' + subjectOwnerId;
  console.log('Uploading to:', baseBucketPath);
  // Ugh, this middle bit very temporary, thumbnail creation and upload
  // will also be needed, in addition we need to know how long the video is
  // ie metadata isn't getting get here.
  const videoUploadPromises = [];
  let videoUploadTasks:firebase.default.storage.UploadTaskSnapshot[] = [];
  const totalVideos = videosForUpload.length;
  let currentVideoCount = 1;
  // Upload the videos first, get their storage information for later useage
  for(const video of videosForUpload){
    const bucketLocation = baseBucketPath + '/' + video.name;
    
    var metadata = {
      contentType: 'video/mp4'
    };

    console.log('Uploading video for asmt');
    // not sure if an await will work
    const uploadTask = firebaseStorage.ref(bucketLocation).put(video,metadata);


    videoUploadPromises.push(uploadTask);
    uploadTask.on('state_changed', 
      (snapShot) => {
      //takes a snap shot of the process as it is happening
        // console.log('The details', snapShot);
        const data:AssessmentCreationFeedBackUIProps = {
          bytesTransferred:snapShot.bytesTransferred,
          totalSize:snapShot.totalBytes,
          fileName: video.name,
          videoTotal:totalVideos,
          currentVideoCount:currentVideoCount,
          genericMessage:''
        };
        setStatus(data);
      }, (err) => {
      //catches the errors
        console.log(err);
      });
    // remove this part if we want al lthe promises at once
    const result = await Promise.all(videoUploadPromises);
    //if success remove one, the result might be what is left in the promise array...
    videoUploadPromises.shift();
    videoUploadTasks = videoUploadTasks.concat(result);
    currentVideoCount +=1;
  }
  return videoUploadTasks;
}

export async function reprocessVideo(firebaseUser:FirebaseUserProps|undefined, asmtid:string) {
  if(!firebaseUser) return;
  console.log('Reprocessing video');

  const asmtPayload = await getAssesmentById(firebaseUser, asmtid) as AssessmentSchemaProps;

  const subjectDetails = await getSubjectFromId(firebaseUser, asmtPayload?.subjectId);
  console.log('Subject details:', subjectDetails);
  let duedate = subjectDetails?.duedate;
  console.log('Duedate:', duedate);
  let videodate = asmtPayload.date;

  if (duedate != null && videodate != null){
    const hoursInWeek = 7 * 24 ;
    const weeks =  (new Date(videodate).getTime() - new Date(duedate).getTime()) / (3600000 * hoursInWeek);
    let adjustedWeeks = weeks < 0? 0: weeks;
    console.log('Weeks at video time:', adjustedWeeks);
    adjustedWeeks = Math.floor(adjustedWeeks+0.49);
    console.log('Rounded Weeks at video time:', adjustedWeeks);
    let newasmtData = {...asmtPayload, additionalFlags: {"age": adjustedWeeks}};

    // initiate processing
    console.log('Initiate process with:', newasmtData);
    const result = initiateProcessing(firebaseUser, newasmtData);
    console.log('Kicked off processing:', result);
  }


}

export  function deleteCloudVideo(firebaseUser: FirebaseUserProps|undefined, videoUrl:string) {
  console.log('deleteCloudVideo:', videoUrl);
  //const storageRef =  firebaseStorage.ref(videoUrl).child(videoUrl);
  const storageRef =  firebaseStorage.refFromURL(videoUrl);
  storageRef.delete().then( ()=> {
    console.log('Cloud Video file deleted');
  }).catch ((error) => {
    console.log('Error deleting cloud file:', error);
  })

}

export function sleep(ms:number) {
return new Promise(resolve => setTimeout(resolve, ms));
}

