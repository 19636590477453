import React, {useContext, useState, useEffect} from 'react';
import {
    Button,
  Card,
  CardContent,
  Divider,
  Dialog,
  DialogContent,
  DialogActions,
  Grid,
  Typography,
  MenuItem,
  TextField
} from '@material-ui/core';

import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { 
  createStyles,
  makeStyles,
  Theme
} from '@material-ui/core/styles';

import { WeekAndDayInput } from '../components/Inputs/WeekAndDayInput';
import { calculateGestAgeFromExpected, calculateWeeksAndDaysFromDays, prettifyMonthDay } 
from '../common/dateTimeUtilities';
import { useUserState } from '../context/UserContext';
import { SubjectActionTypes, useSubjectState, useSubjectDispatch } from '../context/SubjectContext';
import { saveSubjectInfo, setUserInfo } from '../common/apiSetUtilities';
import { UserProps } from '../common/entityUtilities';
import { CpapUserSchemaProps, emptySubjectState, emptyUserMongoSchema } from '../common/mongoSchemas';
import { getSubjectDetails } from '../common/apiGetUtilities';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .MuiTextField-root': {
        margin: theme.spacing(1),
        width: '25ch',
      },
    },
    card: {
      maxWidth: '100%',
      minWidth: '0',
      width:'100%',
      margin: 'auto',
      transition: '0.3s',
      boxShadow: '0 8px 40px -12px rgba(0,0,0,0.3)',
      '&:hover': {
        boxShadow: '0 16px 70px -12.125px rgba(0,0,0,0.3)'
      }
    },
    cardcontent: {
      textAlign: 'left',
      // padding: theme.spacing.unit * 3
    },    
  }),
);

export const SubjectInfo = (props:any): JSX.Element => {

    const {t, i18n} = useTranslation();
    const classes = useStyles();
    const {isOpen, handleClose, parentId} = props;
    const [gestAgeString, setGestAgeString] = React.useState('');
    const [unusedValue, setUnusedValue] = useState<Date>(new Date());
    const [dob, setDob] = useState<Date|undefined>();
    const [dueDate, setDueDate] = useState<Date|undefined>();
    const [gestAgeDays, setGestAgeDays] = useState(0);

    const loggedinUser = useUserState();
    const subject = useSubjectState();
    const subjectDispatch = useSubjectDispatch();

    const saveAndClose = async ()=>{
        // save subject and info first
        var localParentId = parentId;

        if (!parentId) {
            console.log('Parent id is not set:', parentId);
            localParentId = loggedinUser?._id;
        }
        //console.log('Loggedinuser details:', loggedinUser);
        if(loggedinUser?._id == undefined){
            console.log('Save loggedinuser first as parent id:', loggedinUser?._id);
            let userProps = fillupUser(loggedinUser);
            let responseData = await setUserInfo(loggedinUser?.firebaseUser, loggedinUser?._id, userProps);
            console.log('Response data from api:', responseData);
        }

        // save subject 
        // just one subject for a logged in user
        console.log('Subject:', subject);
        const subjectData = emptySubjectState;
        subjectData.dob = dob && dob;
        subjectData.duedate = dueDate && dueDate;
        subjectData.gestage = gestAgeDays;
        subjectData.parentid = localParentId;

        if(loggedinUser?.firebaseUser){
            let datafromapi = await getSubjectDetails(loggedinUser.firebaseUser, localParentId);
            if (!datafromapi){
                // create new subject
                console.log('Create new subject for parent:', parentId);
                await saveSubjectInfo(loggedinUser.firebaseUser, subjectData, undefined).then(updatedSubject=>{
                  console.log('New subject created:', updatedSubject);
                  subjectDispatch({type:SubjectActionTypes.UPDATE, ...updatedSubject});
                });
            }
            else{
                let subjectid = datafromapi._id;
                console.log('Updating subject with id:', subjectid);
                
                const subjectId: string|undefined = datafromapi?._id;
                await saveSubjectInfo(loggedinUser.firebaseUser, subjectData, subjectId).then(updatedSubject=>{

                  console.log('Subject updated:', updatedSubject);
                  subjectDispatch({type:SubjectActionTypes.UPDATE, ...updatedSubject});
                });
            }
        }
        handleClose();
    };



    useEffect( ()=> {
      if(subject?.parentid !== ''){
        //console.log('Subject changed:', subject);
        if(subject.dob){
            setDob(subject.dob);
        }
        if(subject.duedate){
            setDueDate(subject.duedate);
        }
        setGestAgeDays(subject.gestage);
      }
    }, [subject]);

    useEffect(()=>{
        if(dob && dueDate){
            const weekDays = calculateGestAgeFromExpected(dueDate, dob);
            if(weekDays.totalDays){
                
                if (i18n.language=='en'){
                  setGestAgeString(prettifyMonthDay(weekDays.weeks, weekDays.days, true));
                }
                else if (i18n.language=='es'){
                  setGestAgeString( weekDays.weeks + ' ' + t('weeks',{ns:'infantinfo'}) + ', ' + weekDays.days + ' ' + t('days',{ns:'infantinfo'}));  
                // convert to spanish if needed
                }
                console.log('Gest Days:', weekDays.totalDays);
                setGestAgeDays(weekDays.totalDays);
            }
        }
        
    },[dueDate,dob, gestAgeDays]);

    useEffect(()=>{
      if (parentId == undefined) return;

        async function getSubjectFromApi () {
          if(!loggedinUser.firebaseUser) return;
          const subjectDetails = await getSubjectDetails(loggedinUser.firebaseUser, parentId);
          console.log('[useEffect...subject] for parentid:', subjectDetails);
          if (!subjectDetails) return;
          if(subjectDetails?.parentid !== ''){
            //console.log('[useEffect...Dispatching subject details:', subjectDetails);
            subjectDispatch({type:SubjectActionTypes.UPDATE,...subjectDetails});
        };
      }

      //console.log('getting subject for parent id:', parentId);
      getSubjectFromApi();
    }, [parentId]);

    function fillupUser(userData:any){
        const user = emptyUserMongoSchema;
        user.authLevel = 0;
        user.email = userData.localEmail;
        user.firstname = userData.firstname;
        user.lastname  = userData.lastname;
        user.uid = userData.firebaseUser.uid;
        return user;

    }
    return (
       
        
       <Dialog open={isOpen}>
        <DialogContent>
        <Card className={classes.card}>
        <CardContent className={classes.cardcontent}>
        <Grid
                container
                spacing={1}
                xs={10}
                sm={10}
                md={10}
          >
         <Grid item xs={12} style={{minWidth: 256}}>
          <Typography
            color="secondary"
            gutterBottom
            variant={'h6'}
          >
          {t('title', {ns:'infantinfo'})}
          </Typography>
  
          <form
            autoComplete="off"
            className={classes.root}
            noValidate
          >

            <div>
              <KeyboardDatePicker
                KeyboardButtonProps={{
                    'aria-label': 'change date',
                }}
                format={i18n.language=='en'? "MM/dd/yyyy": "dd/MM/yyyy"}
                label={t('dob', {ns:'infantinfo'})}
                onChange={(date: Date|null)=>{date && setDob(date);}}
                value={dob}
              />
              {
              <KeyboardDatePicker
              KeyboardButtonProps={{
                  'aria-label': 'change date',
              }}
                label={t('duedate', {ns:'infantinfo'})}
                format={i18n.language=='en'? "MM/dd/yyyy": "dd/MM/yyyy"}
                onChange={(date:Date|null)=>{date && setDueDate(date);}}
                value={dueDate}
              />
              }
              <TextField
                fullWidth
                disabled
                id="gestationalAge"
                label={t('gestage', {ns:'infantinfo'})}
                value = {gestAgeString}
              />
              {/* <WeekAndDayInput
                initialGestationAgeInDays={gestAgeToDisplay??0}
              /> */}
            </div>

          </form>
          </Grid>
        </Grid>
        </CardContent>
        </Card>
        </DialogContent>
        <DialogActions>

          <Button
            color="primary"
            onClick={saveAndClose}
          >
            {t('close', {ns:'infantinfo'})}
          </Button>

        </DialogActions>
       </Dialog>
    );
}